import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import logo from "../assets/logo.png";
import axios from "axios";
import { useLocation } from "react-router-dom";

const LandingPage = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [data, setData] = useState(null);
  const [buttonCounts, setButtonCounts] = useState([]);
  const [name, setName] = useState(""); // Name from the previous page
  const [email, setEmail] = useState(""); // Email from the previous page
  const [urlData, setUrlData] = useState(null);
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");

  const location = useLocation();

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://adminpanel.freeh2o.store/decode",
        {
          params: { encodedUrl: window.location.href },
        }
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  const fetchButtonCounts = async () => {
    try {
      const response = await axios.get(
        "https://adminpanel.freeh2o.store/buttonCounts"
      );
      setButtonCounts(response.data);
    } catch (error) {
      console.error("Error fetching button counts", error);
    }
  };

  const handleClick = async (buttonType) => {
    try {
      await axios.post("https://adminpanel.freeh2o.store/buttons", {
        buttonType,
        name,
        email,
      });
      console.log("error addding");
      fetchButtonCounts();
    } catch (error) {
      console.error("Error sending button click count", error);
    }
  };

  useEffect(() => {
    // Extract name and email from location state if available

    if (location.state) {
      const { name, email, link1, link2, link3 } = location.state;
      setName(name);
      setEmail(email);
      setUrlData(urlData);
      setLink1(link1);
      setLink2(link2);
      setLink3(link3);
      console.log(name, email, link1, link2, link3, "akash");
    }
    console.log(urlData, "akii");
    fetchData();
    fetchButtonCounts();
  }, [location.state]);

  return (
    <div className=" bg-[#28282b]">
      <div className={`container ${isDarkMode ? "dark-mode" : "light-mode"}`}>
        <div className="glass-effect animated fadeIn ">
          <div className="w-full flex justify-center">
            <img src={logo} alt="Company Logo" className="logo" />
          </div>
          <div className="button-container animated fadeInUp">
            <a
              href={
                link1 && (link1.startsWith("http") ? link1 : `https://${link1}`)
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="button"
                onClick={() => handleClick("offerPage")}
              >
                Offer Page
              </button>
            </a>
            <a
              href={
                link2 && (link2.startsWith("http") ? link2 : `https://${link2}`)
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="button"
                onClick={() => handleClick("clientCompanyPage")}
              >
                Client Company Page
              </button>
            </a>
            <a
              href={
                link3 && (link3.startsWith("http") ? link3 : `https://${link3}`)
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="button"
                onClick={() => handleClick("freeH2OPage")}
              >
                Free H2O Page
              </button>
            </a>
          </div>
          {/* <div className="counts-display">
          <h2>Button Counts</h2>
          <ul>
            {buttonCounts.map((count) => (
              <li key={count.buttonType}>
                {count.buttonType}: {count.count} (Added by: {count.name} -{" "}
                {count.email})
              </li>
            ))}
          </ul>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
