import React, { useEffect, useState } from "react";
import axios from "axios";

function ButtonCountPage() {
  const [buttonCounts, setButtonCounts] = useState({});
  const [buttonData, setButtonData] = useState({
    offerPage: [],
    clientCompanyPage: [],
    freeH2OPage: [],
  });
  const [selectedButton, setSelectedButton] = useState("offerPage");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://adminpanel.freeh2o.store/buttonCounts"
        );

        // Group data by button type and count occurrences
        const data = response.data.reduce(
          (acc, { buttonType, name, email }) => {
            if (!acc.buttonData[buttonType]) {
              acc.buttonData[buttonType] = [];
            }
            acc.buttonData[buttonType].push({ name, email });

            // Count occurrences
            acc.buttonCounts[buttonType] =
              (acc.buttonCounts[buttonType] || 0) + 1;

            return acc;
          },
          { buttonData: {}, buttonCounts: {} }
        );

        setButtonData(data.buttonData);
        setButtonCounts(data.buttonCounts);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = (type) => {
    if (type === "allCustomers") {
      // Aggregate all data into one array
      const allData = [
        ...buttonData.offerPage,
        ...buttonData.clientCompanyPage,
        ...buttonData.freeH2OPage,
      ];
      setSelectedButton("allCustomers");
      setButtonData((prevData) => ({
        ...prevData,
        allCustomers: allData,
      }));
    } else {
      setSelectedButton(type === selectedButton ? null : type);
    }
  };

  const renderButtonContent = () => {
    if (!selectedButton) return null;

    return (
      <table className="table-auto w-full mt-6 border-collapse text-left">
        <thead>
          <tr>
            <th className="border px-4 py-2">Name</th>
            <th className="border px-4 py-2">Email</th>
          </tr>
        </thead>
        <tbody>
          {buttonData[selectedButton]?.map((item, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{item.name}</td>
              <td className="border px-4 py-2">{item.email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className="md:p-6 p-2">
      <div className="max-w-[1280px] border-[1px] border-black mx-auto p-5 rounded-[8px]">
        <div className="flex md:flex-row flex-col md:gap-20 gap-5 justify-center">
          <div className="text-center">
            <h1 className="font-semibold text-[40px]">
              {buttonCounts.offerPage || 0}
            </h1>
            <h2 className="font-bold">Offer Page</h2>
          </div>
          <div className="text-center">
            <h1 className="font-semibold text-[40px]">
              {buttonCounts.clientCompanyPage || 0}
            </h1>
            <h2 className="font-bold">Client Company Page</h2>
          </div>
          <div className="text-center">
            <h1 className="font-semibold text-[40px]">
              {buttonCounts.freeH2OPage || 0}
            </h1>
            <h2 className="font-bold">Freeh2O Page</h2>
          </div>
        </div>
      </div>

      <div className="md:p-6 mt-2 ">
        <div className="max-w-[1280px] border-[1px] border-black mx-auto p-5 rounded-[8px]">
          <div className="flex md:flex-row flex-col md:gap-0 gap-2 justify-center ">
            <div className="text-center border-[1px] border-black p-2 md:rounded-l-[10px] md:border-r-0">
              <button
                onClick={() => handleClick("offerPage")}
                className="hover:underline"
              >
                <h2 className="font-bold">Offer Page</h2>
              </button>
            </div>
            <div className="text-center border-[1px] border-black p-2 md:border-r-0">
              <button
                onClick={() => handleClick("clientCompanyPage")}
                className="hover:underline"
              >
                <h2 className="font-bold">Client Company Page</h2>
              </button>
            </div>
            <div className="text-center border-[1px] border-black p-2 md:border-r-0">
              <button
                onClick={() => handleClick("freeH2OPage")}
                className="hover:underline"
              >
                <h2 className="font-bold">Freeh2O Page</h2>
              </button>
            </div>
            <div className="text-center border-[1px] border-black p-2 md:rounded-r-[10px]">
              <button
                onClick={() => handleClick("allCustomers")}
                className="hover:underline"
              >
                <h2 className="font-bold">All Customers</h2>
              </button>
            </div>
          </div>

          <div className="mt-6 flex flex-col w-full justify-center text-center">
            {renderButtonContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ButtonCountPage;
