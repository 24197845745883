import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./WelcomeH2OPage.css";
import logo from "../assets/logo.png"; // Adjust the path to your logo
import LandingPage from "../../src/components/LandingPage";

const WelcomeH2OPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState(""); // Renamed to `contact` to cover both phone number and email
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [urlData, setUrlData] = useState(null);
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  const [load, setLoad] = useState(null);

  const validateForm = () => {
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!name || (!email.match(phoneRegex) && !email.match(emailRegex))) {
      setError("Please enter a valid name and a valid phone number or email.");
      return false;
    }
    setError("");
    return true;
  };

  const fetchUrlData = async () => {
    try {
      // Ensure the URL parameter is properly encoded
      const encodedUrl = encodeURIComponent(window.location.href);
      const response = await axios.get(
        "https://adminpanel.freeh2o.store/decode",
        {
          params: { encodedUrl },
        }
      );

      setUrlData(response.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchUrlData();
    console.log(urlData, "urlData");
  }, []); // Empty dependency array to run only once on mount

  useEffect(() => {
    if (urlData) {
      console.log(urlData, "urlData");
      setLink1(urlData.additionalData.link1);
      setLink2(urlData.additionalData.link2);
      setLink3(urlData.additionalData.link3);
    }
    console.log(link1, "link11");
  }, [urlData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        // Send data to the API using axios
        const response = await axios.post(
          "https://adminpanel.freeh2o.store/contacts",
          {
            name,
            email,
          }
        );

        // Handle the response if needed
        console.log(response.data);

        // Navigate to landing page with state
        // navigate("/landingPage", {
        //   state: { name, email, link1, link2, link3 },
        // });

        window.location.href =
          "https://www.instagram.com/free_h2o.lk?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==";
        console.log(urlData, "urlData");
      } catch (error) {
        console.error("Error submitting form:", error);
        setError("There was an error submitting the form. Please try again.");
      }
    }
  };

  // Assuming the route for LandingPage is set to "/landing"
  const handleGetStartedClick = () => {
    navigate("/landingPage");
    console.log(urlData, "link11");
  };

  return (
    <div className="bg-[#28282b]">
      <div className="h2o-container">
        <img src={logo} alt="Logo" className="h2o-logo" />
        {/* <h1 className="h2o-title">Welcome to Free H2O</h1> */}
        <form onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}
          <div className="input-group">
            <input
              type="text"
              id="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className={error ? "error" : ""}
            />
          </div>
          <div className="input-group">
            <input
              type="text"
              id="contact"
              placeholder="Phone Number/Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={error ? "error" : ""}
            />
          </div>
          {/* <button type="submit" className="h2o-button">
          Get Started
        </button> */}
          <button type="button" className="h2o-button" onClick={handleSubmit}>
            Get a FreeH2O
          </button>
        </form>
      </div>
    </div>
  );
};

export default WelcomeH2OPage;
