import React from "react";
import WelcomeH2OPage from "./components/WelcomeH2OPage";
import LoginComponent from "./components/loginComponent";
import AdminPanel from "./pages/adminPanel";
import MainPage from "./pages/mainPage";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
} from "react-router-dom";
import ButtonCountPage from "./pages/buttonCountPage";
import LandingPage from "./components/LandingPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<WelcomeH2OPage />} />
        <Route path="/landingPage" element={<LandingPage />} />
        <Route path="/adminpanel" element={<MainPage />} />
        <Route path="/createqr" element={<AdminPanel />} />
        <Route path="/calculateCount" element={<ButtonCountPage />} />
      </Routes>
    </Router>
  );
};

export default App;
