import React from "react";
import { Link } from "react-router-dom";

function MainPage() {
  return (
    <div className="flex flex-col items-center p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Main Page</h1>

      <button className="py-2 px-4 bg-blue-600 text-white rounded-md shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mb-4">
        <Link to="/createqr"> Create QR </Link>
      </button>

      <button className="py-2 px-4 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50">
        <Link to="/calculateCount"> Calculate Count </Link>
      </button>
    </div>
  );
}

export default MainPage;
