import React, { useState } from "react";
import axios from "axios";

function AdminPanel() {
  const [formData, setFormData] = useState({
    url: "https://freeh2o.store/",
    additionalData: {
      link1: "",
      link2: "",
      link3: "",
    },
  });
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith("additionalData.")) {
      const key = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        additionalData: {
          ...prevData.additionalData,
          [key]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Form Data:", formData); // Print form data to check the values

    try {
      const response = await axios.post(
        "https://adminpanel.freeh2o.store/qrcode",
        formData,
        { responseType: "arraybuffer" } // Specify that the response is binary
      );

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1]; // Remove the data URL prefix
        const qrCodeUrl = `data:image/png;base64,${base64}`;
        setQrCodeUrl(qrCodeUrl);
        console.log(qrCodeUrl, "QR Code URL");
      };

      reader.readAsDataURL(new Blob([response.data]));
    } catch (error) {
      console.error("Error generating QR code", error);
    }
  };

  const downloadQRCode = () => {
    if (!qrCodeUrl) {
      console.error("QR code URL is not set.");
      return;
    }

    // Create an anchor element
    const link = document.createElement("a");

    // Set the href to the QR code URL and trigger a download
    link.href = qrCodeUrl;
    link.download = "qrcode.png";

    // Append to body, click to download, then remove
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="flex flex-col items-center p-6  min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Admin Panel</h1>
      <form
        className="w-full max-w-md bg-white p-6 rounded-lg shadow-md"
        onSubmit={handleSubmit}
      >
        <div className="space-y-4">
          <div>
            <label
              htmlFor="url"
              className="block text-sm font-medium text-gray-700"
            >
              URL
            </label>
            <input
              id="url"
              name="url"
              type="text"
              value={formData.url}
              onChange={handleChange}
              className="mt-1 text-black block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm"
              placeholder="Enter URL"
            />
          </div>

          <div>
            <label
              htmlFor="link1"
              className="block text-sm font-medium text-gray-700"
            >
              Link 1
            </label>
            <input
              id="link1"
              name="additionalData.link1"
              type="text"
              value={formData.additionalData.link1}
              onChange={handleChange}
              className="mt-1 text-black block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm"
              placeholder="Enter Link 1"
            />
          </div>

          <div>
            <label
              htmlFor="link2"
              className="block text-sm font-medium text-gray-700"
            >
              Link 2
            </label>
            <input
              id="link2"
              name="additionalData.link2"
              type="text"
              value={formData.additionalData.link2}
              onChange={handleChange}
              className="mt-1 text-black block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm"
              placeholder="Enter Link 2"
            />
          </div>

          <div>
            <label
              htmlFor="link3"
              className="block text-sm font-medium text-gray-700"
            >
              Link 3
            </label>
            <input
              id="link3"
              name="additionalData.link3"
              type="text"
              value={formData.additionalData.link3}
              onChange={handleChange}
              className="mt-1 text-black block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-sm"
              placeholder="Enter Link 3"
            />
          </div>

          <div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-indigo-600 text-white rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
            >
              Generate QR Code
            </button>
          </div>
        </div>
      </form>

      {qrCodeUrl && (
        <div className="mt-6">
          <img src={qrCodeUrl} alt="QR Code" className="max-w-xs" />
          <button
            onClick={downloadQRCode}
            className="mt-4 py-2 px-4 bg-green-600 text-white rounded-md shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          >
            Download QR Code
          </button>
        </div>
      )}
    </div>
  );
}

export default AdminPanel;
